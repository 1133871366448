import request from '@/request'

export const queryMessage = (data) => {
    return request({
        url:`/api-gateway/service/queryMessage`,
        method:'post',
        data,
    });
}
export const addMessage = (data) => {
    return request({
        url:'/api-gateway/service/addMessage',
        method:'post',
        data,
    });
}