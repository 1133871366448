<script>

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {addMessage,queryMessage} from '@/apis/message'
import {
  required
} from "vuelidate/lib/validators";
/**
 * Form Repeater component
 */
export default {
  page: {
    title: "跟进",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout},
  validations: {
    form: {
      content: { 
            required,
      },
    },
  },
  data() {
    return {
      messageList:[],
      form:{
        affiliatedNo:'',
        messageType:'',
        content:'',
      },
      submitted:false,
    };
  },
  created(){
    if(this.$route.query.id!=undefined){
      this.form.affiliatedNo = this.$route.query.id;
    }
    if(this.$route.query.type!=undefined){
      this.form.messageType = this.$route.query.type;
    }
    this.loadData()
  },
  methods: {
    loadData(){
      queryMessage({filter:this.form}).then((res)=>{
            this.messageList = res.data;
      })
    },
    handleSaveFollowup() {
      this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        } else {
          let params = {...this.form}
          addMessage(params).then(()=>{
            this.submitted = false;
            this.form.content = ""
            this.loadData();
          })
        }
    },
    handleBack() {
      this.$router.go(-1)
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row  mt-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="card-title mb-4">{{$t("order.followupRecord")}}</h5>
              </div>
            </div>
            <div class="row mb-3">
                      <div class="col-lg-10 col-sm-6">
                          <div class="search-box mb-2 me-2">
                              <div class="position-relative">
                                  <input type="search" v-model="form.content" class="form-control bg-light border-light rounded" placeholder="message"
                                  :class="{'is-invalid': submitted && $v.form.content.$error,
                                                    }">
                                    <div
                                        v-if="submitted && $v.form.content.$error"
                                        class="invalid-feedback"
                                        >
                                            <span v-if="!$v.form.content.required">
                                              {{$t("order.infoEnterContent")}}
                                            </span>
                                    </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-2 col-sm-6">
                        <div class="d-flex flex-wrap mt-2 mt-md-0 gap-2 mb-3">
                              <div>
                                  <ul class="nav nav-pills">
                                      <li class="nav-item">
                                          <b-button variant="primary"  @click="handleSaveFollowup">{{$t("actions.add")}}</b-button>
                                      </li>
                                      <li class="nav-item ms-1">
                                        <b-button variant="light"  @click="handleBack">{{$t("actions.back")}}</b-button>
                                      </li>                                     
                                  </ul>
                              </div>                                               
                          </div>                        
                      </div>
            </div>
            <div class="row">
              <div class="messages">
                <div class="message-item"  v-for="(item) in messageList" :key="item.messageId">
                    <span class="icon"></span>
                      <h5>{{item.createDate}}</h5>
                      <p><span class="badge badge-soft-success me-1">{{item.createUser}}</span>{{item.content}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
    .messages{
      border-left: #bfcbd9 solid 2px;
      padding-left: 20px;
      margin-left: 20px;
        .message-item {
          position: relative;
          span.icon{
            border-radius: 100%;
            border: #bfcbd9 solid 1px;
            background-color: #bfcbd9;
            width: 15px;
            height: 15px;
            font-size: 10px;
            display: inline-block;
            text-align: center;
            position: absolute;
            left: -29px;
            top:4px;
          }
        }
    }
</style>
